html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family:
    var(--font-optima),
    var(--font-proximaNova),
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Helvetica Neue,
    sans-serif;
}

html:has(dialog[open]) {
  overflow: hidden !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Brand colors */
    --primary: 223 201 168; /* beige-500 */
    --primary-foreground: 49 49 49; /* #313131 */

    --secondary: 49 49 49; /* #313131 */
    --secondary-foreground: 255 255 255; /* white */

    --tertiary: 255 255 255; /* white */
    --tertiary-foreground: 49 49 49; /* #313131 */

    --popover: 255 255 255;
    --popover-foreground: 49 49 49;

    --input: 229 231 235;
    --input-focus: 49 49 49;
    --input-foreground: 49 49 49;
    --input-ring: 255 0 0;
    --input-muted: 243 244 246;

    --destructive: 225 29 71;
  }
}

@layer utilities {
  summary::-webkit-details-marker {
    display: none; /* This also hides the ::marker pseudo-element, but in Safari */
  }

  .text-shadow {
    text-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  }
  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #f3f4f6;
  }

  .scrollbar-m::-webkit-scrollbar-track {
    background: #f0eada;
    margin-left: 5px;
    margin-right: 5px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 15px;
    border: 3px solid #484848;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #7c7c7c;
  }
}

select {
  background-image: url("../../public/images/icons/chevron-down.svg");
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  background-size: 14px;
}

/* Hide arrows on number input */
@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.text-shadow {
  text-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
}

/* Hide scrollbar */
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input {
  font-size: 16px !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.text:first-letter {
  text-transform: uppercase;
}

.text-dinh:first-letter {
  text-transform: none;
}

.custom-markup {
  white-space: pre-line;
}

.custom-markup a,
p > a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
  font-weight: 300;
}
.custom-markup ul {
  margin: 0 0 0 24px;
}

.custom-markup li {
  margin-top: 10px;
  list-style-type: disc;
  padding-left: 8px;
}

.custom-markup {
  table {
    display: table;
    font-size: 0.75rem;
    border: rgb(209 213 219) 1px solid;
  }
  th {
    background-color: white;
    border: rgb(209 213 219) 1px solid;
    padding: 0.5rem;
  }
  td {
    border: rgb(209 213 219) 1px solid;
    padding: 0.5rem;
  }
  tr:nth-child(even) {
    background-color: white;
  }
  tr:nth-child(odd) {
    background-color: rgb(243 244 246);
  }
  h3 {
    font-family: var(--font-optima);
    font-size: x-large;
    color: var(--secondary);
    margin-top: 0.5rem;
  }
  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.hide-scrollbar {
  /* Hide scrollbar in Firefox */
  scrollbar-width: none;
  /* Hide scrollbar in IE and Edge */
  -ms-overflow-style: none;
}

/* Hide scrollbar in webkit */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
